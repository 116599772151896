import React from "react"
import { Link } from "gatsby"

export default function Pagination({ rootpath, numPages, currentPage }) {
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const firstPath = (rootpath === "/") ? "/": rootpath + (1).toString()
    const lastPath = rootpath + (numPages).toString()

    let nextPath = rootpath + (currentPage + 1).toString()
    let nextNextPath = rootpath + (currentPage + 2).toString()
    let prevPath = (rootpath === "/" && currentPage === 2) ? "/" : rootpath + (currentPage - 1).toString()
    let prevPrevPath = rootpath + (currentPage - 1).toString()
    
    let nextEnable = currentPage + 1 <= numPages && !isLast
    let nextNextEnable = currentPage + 2 <= numPages && isFirst
    let prevEnable = currentPage -1 > 0 && !isFirst
    let prevPrevEnable = currentPage - 2 > 0 && isLast

    if (isFirst && isLast) {
        nextEnable = false
    }

    const NumberButton = ({pageNumber, pagePath}) => (
        <li className="page-item">
            <Link className="page-link" aria-label="Next" to={pagePath} rel={pageNumber.toString()}>
                <span className="text-dark">{pageNumber}</span>
            </Link>
        </li>
    )

    return(
        <div className="text-center fs-5">
            <nav aria-label="Pagination">
                <ul className="pagination">
                    <li className={isFirst ? "page-item disabled" : "page-item"}>
                        <Link className="page-link" aria-label="Previous" to={firstPath} rel="prev">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="visually-hidden">Previous</span>
                        </Link>
                    </li>

                    {prevPrevEnable && <NumberButton pageNumber={currentPage-2} pagePath={prevPrevPath} />}
                    {prevEnable && <NumberButton pageNumber={currentPage-1} pagePath={prevPath} />}
                    <li className="page-item disabled">
                        <span className="page-link text-dark bg-light">{currentPage}</span>
                    </li>
                    {nextEnable && <NumberButton pageNumber={currentPage+1} pagePath={nextPath} />}
                    {nextNextEnable && <NumberButton pageNumber={currentPage+2} pagePath={nextNextPath} />}
                    
                    <li className={isLast ? "page-item disabled" : "page-item"}>
                        <Link className="page-link" aria-label="Next" to={lastPath} rel="next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="visually-hidden">Next</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

Pagination.defaultProps = {
    rootpath: "/",
    numPages: 1,
    currentPage: 1,
}
import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/layout"
import ArticleCard from "../components/articleCard"
import Pagination from "../components/pagination"
import Breadcrumb from "../components/breadcrumb"

const PostList = (props) => {
    const siteMetadata = props.data.site.siteMetadata
    const posts = props.data.allMarkdownRemark.edges
    const { currentPage, numPages } = props.pageContext
    return (
        <Layout title="Home" adsense={true} path={props.location.pathname}>
            <div className="mt-3">
                <Breadcrumb title="Home" />
            </div>
            <div className="">
                {posts.map((post) => (
                    <ArticleCard
                        key = {post.node.id}
                        slug = {post.node.fields.slug}
                        title = {post.node.frontmatter.title}
                        date = {post.node.frontmatter.date}
                        excerpt = {post.node.excerpt}
                        tags = {post.node.frontmatter.tags}
                        labels = {siteMetadata.labels}
                    />
                ))}
            </div>
            <div className="d-flex justify-content-center mt-4">
                <Pagination 
                    rootpath="/"
                    currentPage={currentPage}
                    numPages={numPages}
                />
            </div>
        </Layout>
    )
}

export const paginateQuery = graphql`
    query paginateQuery($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                labels {
                    tag
                    title
                    svg 
                    size 
                    color
                }
            }
        }
        allMarkdownRemark(
            limit: $limit
            skip: $skip
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true } } }
        ) {
            totalCount
            edges {
                node {
                    excerpt(pruneLength: 140, truncate: true)
                    html
                    id
                    frontmatter {
                        title
                        date(formatString: "YYYY年M月D日")
                        tags
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`

export default PostList
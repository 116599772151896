import React from "react"
import { Link } from "gatsby"

import { getTechTags } from "./techTag"

export default function ArticleCard({ slug, title, date, excerpt, tags, labels }) {
    return(
        <div className="my-2 g-0 border border-1 rounded overflow-hidden shadow-sm">
            <div className="p-3 flex-column position-static">
                <h3 className="title h3">
                    <Link to={slug} className="link-title text-decoration-none">{title}</Link>
                </h3>
                <div className="text-muted">
                    最終更新日: {date}
                </div>
                <div className="my-1" style={{color: "#334854"}}>
                    {excerpt}&nbsp;
                    <span className="d-inline-block">
                        <Link to={slug} className="link-success text-decoration-none">続きを読む</Link>
                    </span>
                </div>
                <div className="">
                    {getTechTags(tags, labels)}
                </div>
            </div>
        </div>
    )
}

ArticleCard.defaultProps = {
    id: "",
    slug: "/",
    title: "",
    date: "",
    exerpt: "",
    tags: [],
    labels: [],
}